import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { TranslationKey } from '../../i18n/i18n';
import RectangularButton from '../atoms/RectangularButton';
import AppSpeechBubble from './AppSpeechBubble';
import TextSpeechBubble from './TextSpeechBubble';

interface Props {
	textKey: TranslationKey;
	prevTextKey?: string;
	onPrev?: () => void;
	nextTextKey?: string;
	onNext?: () => void;
}

const AppTextSpeechBubble: FunctionComponent<Props> = ({
	textKey,
	prevTextKey = 'appDetails.previous',
	onPrev,
	nextTextKey = 'appDetails.next',
	onNext
}) => {
	const { t } = useTranslation();

	const prevButton = useMemo(() => {
		if (onPrev) {
			return (
				<RectangularButton key="prev" onClick={onPrev}>
					{t(prevTextKey)}
				</RectangularButton>
			);
		}
	}, [t, prevTextKey, onPrev]);

	const nextButton = useMemo(() => {
		if (onNext) {
			return (
				<RectangularButton key="next" primary onClick={onNext}>
					{t(nextTextKey)}
				</RectangularButton>
			);
		}
	}, [t, nextTextKey, onNext]);

	return (
		<AppSpeechBubble>
			<TextSpeechBubble
				textKey={textKey}
				firstComponent={prevButton}
				lastComponent={nextButton}
			/>
		</AppSpeechBubble>
	);
};

export default AppTextSpeechBubble;
