import styled from 'styled-components';

const SpeechBubble = styled.div`
	border-bottom-right-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	box-sizing: border-box;
	font: ${(props) => props.theme.fonts.speechBubble};
`;

export default SpeechBubble;
