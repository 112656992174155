import type { CSSProperties, FunctionComponent } from 'react';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import type { Area } from '../../data/Areas';
import AREAS from '../../data/Areas';
import type { Person } from '../../data/Persons';
import PERSONS, { getPersonForArea } from '../../data/Persons';
import type { SvgComponent } from '../../assets/Assets';
import ASSETS from '../../assets/Assets';
import styles from './AreaPin.module.css';

interface ContainerProps {
	visible: boolean;
	x: number;
	y: number;
}

const Container = styled.div<ContainerProps>`
	bottom: ${(props) => props.y}px;
	cursor: pointer;
	left: calc(${(props) => props.x}px - (134px / 2));
	position: absolute;
	visibility: ${(props) => (props.visible ? undefined : 'hidden')};
`;

const Image = styled(ASSETS.images.pin)`
	height: 171px;
	width: 134px;
`;

const Icon = styled.div`
	height: 134px;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 134px;
`;

const ICON_STYLE: CSSProperties = {
	marginTop: 20,
	marginLeft: 28,
	marginRight: 28
};

interface Props {
	index: number;
	area: Area;
	visible: boolean;
	x: number;
	y: number;
	animated: boolean;
	onClick: (area: Area, person?: Person) => void;
}

const AreaPin: FunctionComponent<Props> = ({
	index,
	area,
	visible,
	x,
	y,
	animated = true,
	onClick
}) => {
	const { t } = useTranslation();

	const person: Person | undefined = useMemo(
		() => getPersonForArea(area)?.key,
		[area]
	);

	const handleClick = useCallback(
		() => onClick(area, person),
		[onClick, area, person]
	);

	const PersonImage: SvgComponent | undefined = useMemo(() => {
		if (person) {
			return PERSONS[person].image;
		}
	}, [person]);

	return (
		<Container
			className={animated ? styles.bounce : undefined}
			style={{ animationDelay: index * 300 + 'ms' }}
			id={area}
			visible={visible}
			x={x}
			y={y}
			onClick={handleClick}
			title={t(AREAS[area].name)}
		>
			<Image />
			<Icon>{PersonImage && <PersonImage style={ICON_STYLE} />}</Icon>
		</Container>
	);
};

export default AreaPin;
