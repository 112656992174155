import styled from 'styled-components';
import ASSETS from '../../assets/Assets';

const TitleSvg = ASSETS.images.title;

const Title = styled(TitleSvg)`
	position: absolute;
	left: 0;
	top: 0;
	margin-left: calc(117px + 1vw);
	margin-top: 2vh;
	pointer-events: none;
	width: 20vw;
`;

export default Title;
