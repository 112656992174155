import type { FunctionComponent } from 'react';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TranslatedText from '../atoms/TranslatedText';
import RectangularButton from '../atoms/RectangularButton';
import Dialog, { Actions, Title } from '../molecules/Dialog';
import type { Props as DialogProps } from '../molecules/Dialog';
import ASSETS from '../../assets/Assets';

const BerndSvg = styled(ASSETS.images.bernd)`
	min-width: 24vmin;
	width: 24vmin;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
`;

const Description = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: 2em;
	margin-right: 2em;
`;

interface Props1 {
	onSkip: () => void;
	onNext: () => void;
}

const Intro1: FunctionComponent<Props1> = ({ onSkip, onNext }) => {
	const { t } = useTranslation();
	return (
		<>
			<Title>{t('intro.title')}</Title>
			<TranslatedText translationKey="intro.text1" />
			<Actions>
				<RectangularButton width="10em" onClick={onSkip}>
					{t('intro.skip')}
				</RectangularButton>
				<RectangularButton primary width="10em" onClick={onNext}>
					{t('intro.next')}
				</RectangularButton>
			</Actions>
		</>
	);
};

interface Props2 {
	onSkip: () => void;
	onNext: () => void;
}

const Intro2: FunctionComponent<Props2> = ({ onSkip, onNext }) => {
	const { t } = useTranslation();
	return (
		<>
			<TranslatedText translationKey="intro.text2" />
			<Actions>
				<RectangularButton width="10em" onClick={onSkip}>
					{t('intro.skip')}
				</RectangularButton>
				<RectangularButton primary width="10em" onClick={onNext}>
					{t('intro.next')}
				</RectangularButton>
			</Actions>
		</>
	);
};

interface Props extends DialogProps {
	onSkip: () => void;
	onClose: () => void;
}

const IntroDialog: FunctionComponent<Props> = ({
	onSkip,
	onClose,
	...dialogProps
}) => {
	const [index, setIndex] = useState(0);
	const content = useMemo(() => {
		switch (index) {
			case 0:
				return <Intro1 onSkip={onSkip} onNext={() => setIndex(index + 1)} />;
			case 1:
				return <Intro2 onSkip={onSkip} onNext={onClose} />;
		}
	}, [index, setIndex, onSkip, onClose]);

	return (
		<Dialog {...dialogProps}>
			<Content>
				<BerndSvg />
				<Description>{content}</Description>
			</Content>
		</Dialog>
	);
};

export default IntroDialog;
