import { useCallback } from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import styled from 'styled-components';

const StarRating = styled(StarRatingComponent)`
	display: flex !important;
	flex-direction: row-reverse;
	font-size: 250%;
	justify-content: center;
	line-height: 1;
`;

const UnicodeSymbol = styled.span`
	font-family: -apple-system, sans-serif;
`;

interface Props {
	name: string;
	value?: number;
	onChanged: (value: number) => void;
}

const Rating: FunctionComponent<Props> = ({ name, value, onChanged }) => {
	const renderStarIcon = useCallback(
		(starValue: number, currentValue: number): ReactNode => {
			if (currentValue >= starValue) {
				return <UnicodeSymbol>&#9733;</UnicodeSymbol>;
			} else {
				return <UnicodeSymbol>&#9734;</UnicodeSymbol>;
			}
		},
		[]
	);

	return (
		<StarRating
			name={name}
			value={value ?? 0}
			onStarClick={onChanged}
			renderStarIcon={renderStarIcon}
		/>
	);
};

export default Rating;
