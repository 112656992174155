import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import type { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import toursReducer from './ToursSlice';
import appsReducer from './AppsSlice';
import surveyReducer from './SurveySlice';
import type { SurveySliceState } from './SurveySlice';
import { IS_DEBUG } from '../utils/debug';

const surveyPersistConfig: PersistConfig<SurveySliceState> = {
	key: 'Survey',
	storage,
	debug: IS_DEBUG
};

const rootReducer = combineReducers({
	tours: toursReducer,
	apps: appsReducer,
	survey: surveyReducer // persistReducer(surveyPersistConfig, surveyReducer)
});
export type RootState = ReturnType<typeof rootReducer>;

const persistConfig: PersistConfig<RootState> = {
	key: 'Landkarte',
	storage,
	whitelist: [],
	debug: IS_DEBUG
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	devTools: IS_DEBUG
		? {
				name: 'Pfaff Landkarte'
		  }
		: false,
	reducer: persistedReducer
});

export const persistor = persistStore(store);

export default store;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
