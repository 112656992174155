import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import map from 'lodash/map';
import compact from 'lodash/compact';
import AreaPin from './AreaPin';
import type { Area } from '../../data/Areas';

export interface AreaPosition {
	readonly area: Area;
	readonly x: number;
	readonly y: number;
}

interface Props {
	positions: AreaPosition[];
	visible: boolean;
	animated: boolean;
	onAreaSelected: (area: Area) => void;
}

const AreaPinsOverlay: FunctionComponent<Props> = ({
	positions,
	visible,
	animated,
	onAreaSelected
}) => {
	const pins = useMemo(() => {
		return compact(
			map(positions, (position: AreaPosition, index) => {
				return (
					<AreaPin
						key={position.area}
						index={index}
						visible={visible}
						animated={animated}
						onClick={onAreaSelected}
						area={position.area}
						x={position.x}
						y={position.y}
					/>
				);
			})
		);
	}, [positions, onAreaSelected, visible, animated]);

	return <>{pins}</>;
};

export default AreaPinsOverlay;
