import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import RectangularButton from '../atoms/RectangularButton';
import PersonSpeechOverlay from '../molecules/PersonSpeechOverlay';
import PersonTextSpeechBubble from '../molecules/PersonTextSpeechBubble';
import { Person } from '../../data/Persons';

interface Props {
	visible?: boolean;
	onNext: () => void;
}

const MapInto: FunctionComponent<Props> = ({ visible = true, onNext }) => {
	const { t } = useTranslation();

	if (!visible) {
		return null;
	}

	return (
		<PersonSpeechOverlay person={Person.BERND}>
			<PersonTextSpeechBubble
				textKey="mapIntro.text"
				firstComponent={t('mapIntro.clickOnNext')}
				lastComponent={
					<RectangularButton key="next" primary onClick={onNext}>
						{t('mapIntro.next')}
					</RectangularButton>
				}
			/>
		</PersonSpeechOverlay>
	);
};

export default MapInto;
