import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { App } from '../../data/Apps';
import FishAndTipsScreen from '../organisms/FishAndTipsScreen';
import MiniLauternScreen from '../organisms/MiniLauternScreen';
import PfaffFunkScreen from '../organisms/PfaffFunkScreen';
import SimulatorScreen from '../organisms/SimulatorScreen';

interface Props {
	app: App | null;
}

const AppView: FunctionComponent<Props> = ({ app }) => {
	const component = useMemo(() => {
		switch (app) {
			case App.FishAndTipps:
				return <FishAndTipsScreen />;
			case App.MiniLautern:
				return <MiniLauternScreen />;
			case App.PfaffFunk:
				return <PfaffFunkScreen />;
			case App.Simulator:
				return <SimulatorScreen />;
			default:
				return null;
		}
	}, [app]);

	return component;
};

export default AppView;
