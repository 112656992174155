import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import styled from 'styled-components';
import type { Person } from '../../data/Persons';
import PERSONS from '../../data/Persons';
import styles from './Avatar.module.css';

const Overlay = styled.div`
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	pointer-events: none;
	right: 0;
	top: 0;
`;

const TourGuide = styled.div`
	pointer-events: auto;
`;

const Image = styled.div`
	bottom: 0;
	cursor: pointer;
	height: 24vmin;
	left: calc(120px - 6vmin);
	overflow: hidden;
	position: absolute;
	width: 24vmin;
`;

interface Props {
	person: Person;
	closed?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
}

const PersonSpeechOverlay: FunctionComponent<PropsWithChildren<Props>> = ({
	children,
	person,
	closed = false,
	onOpen,
	onClose
}) => {
	const PersonImage = useMemo(() => PERSONS[person].image, [person]);

	const handleClose = useCallback(() => {
		if (!closed) {
			onClose?.();
		}
	}, [closed, onClose]);

	return (
		<Overlay>
			<ClickAwayListener onClickAway={handleClose}>
				<TourGuide>
					<Image className={styles.avatar} onClick={onOpen}>
						<PersonImage />
					</Image>
					{!closed && <>{children}</>}
				</TourGuide>
			</ClickAwayListener>
		</Overlay>
	);
};

export default PersonSpeechOverlay;
