import type { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.backdrop};
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 30;
`;

const Box = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	border: 2px solid ${(props) => props.theme.colors.red};
	border-radius: 10px;
	font: ${(props) => props.theme.fonts.default};
	max-width: 60%;
	min-width: 20em;
	padding: 1.6em;
	position: relative;
	overflow-x: auto;
`;

const Title = styled.div`
	font: ${(props) => props.theme.fonts.title};
	text-align: left;
`;

const Actions = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 1em;
`;

export interface Props {
	visible: boolean;
}

const Dialog: FunctionComponent<PropsWithChildren<Props>> = ({
	children,
	visible
}) => {
	if (!visible) {
		return null;
	}
	return (
		<Backdrop>
			<Box>{children}</Box>
		</Backdrop>
	);
};

export default Dialog;
export { Title, Actions };
