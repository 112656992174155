import { useCallback, useMemo } from 'react';
import type { FunctionComponent } from 'react';
import type { Props as RoundButtonProps } from './RoundButton';
import RoundButton from './RoundButton';
import type { Area } from '../../data/Areas';
import AREAS from '../../data/Areas';
import type { SvgComponent } from '../../assets/Assets';

type Props = Omit<RoundButtonProps, 'active' | 'onClick' | 'size'> & {
	area: Area;
	activeArea?: Area;
	onAreaClicked?: (area: Area) => void;
};

const AreaButton: FunctionComponent<Props> = ({
	area,
	activeArea,
	onAreaClicked,
	...roundButtonProps
}) => {
	const clickHandler = useCallback(() => {
		onAreaClicked?.(area);
	}, [area, onAreaClicked]);
	const Icon: SvgComponent = useMemo(() => AREAS[area].icon, [area]);

	return (
		<RoundButton
			active={area === activeArea}
			onClick={clickHandler}
			size="70px"
			{...roundButtonProps}
		>
			<Icon />
		</RoundButton>
	);
};

export default AreaButton;
