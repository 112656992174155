import type { DefaultTheme } from 'styled-components';

const BLACK = '#2B2B2B';
const DARK_GRAY = '#B3B3B3';
const GRAY = '#DADADA';
const LIGHT_GRAY = '#F5F5F5';
const GRAY_BLUE = '#C5D0EA';
const WHITE = '#FFFFFF';
const LIGHT_RED = '#D89BAA';
const RED = '#C66378';
const LIGHT_BLUE = '#EFFAFF';
const BLUE = '#7BEBFF';

const DEFAULT = 'normal normal normal 2vh/2.8vh Ubuntu, sans-serif';
const BOLD = 'normal normal bold 3.2vh/3.8vh Ubuntu, sans-serif';
const SMALL = 'normal normal normal 1.6vh/1.8vh Ubuntu';
const SMALL2 = 'normal normal normal 1.6vh/2.8vh Ubuntu';

const THEME: DefaultTheme = {
	colors: {
		black: BLACK,
		darkGray: DARK_GRAY,
		gray: GRAY,
		lightGray: LIGHT_GRAY,
		grayBlue: GRAY_BLUE,
		white: WHITE,
		lightRed: LIGHT_RED,
		red: RED,
		lightBlue: LIGHT_BLUE,
		blue: BLUE,
		backdrop: 'rgba(255, 255, 255, 0.5)',
		shadowLight: 'rgba(0, 0, 0, 0.16)',
		shadowDark: 'rgba(0, 0, 0, 0.5)'
	},
	fonts: {
		default: DEFAULT,
		title: BOLD,
		text: SMALL,
		speechBubble: SMALL2,
		button: DEFAULT
	}
};

export default THEME;
