import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useControls, useTransformEffect } from 'react-zoom-pan-pinch';
import type { AreaPosition } from './AreaPinsOverlay';
import AreaPinsOverlay from './AreaPinsOverlay';
import type { AppPosition } from './AppPinsOverlay';
import AppPinsOverlay from './AppPinsOverlay';
import ASSETS from '../../assets/Assets';
import type { Area } from '../../data/Areas';
import type { App } from '../../data/Apps';

export const MAP_WIDTH = 1638;
export const MAP_HEIGHT = 997;

export const MIN_SCALE = 0.5;
export const MAX_SCALE = 2.5;
export const DETAIL_SCALE = 1.5;

const ANIMATION_DURATION = 1_000;

const Plane = styled.div`
	height: ${MAP_HEIGHT}px;
	position: relative;
	width: ${MAP_WIDTH}px;
`;

interface Props {
	currentArea?: Area | null;
	areaPositions: AreaPosition[];
	areaMarkersAnimated: boolean;
	onAreaSelected: (area: Area) => void;
	appPositions: AppPosition[];
	onAppSelected: (app: App) => void;
}

const Map: FunctionComponent<Props> = ({
	currentArea,
	areaPositions,
	areaMarkersAnimated,
	onAreaSelected,
	appPositions,
	onAppSelected
}) => {
	const { zoomToElement, resetTransform } = useControls();
	const [showDetailed, setShowDetailed] = useState(false);

	useTransformEffect(({ state }) => {
		setShowDetailed(state.scale > DETAIL_SCALE);
	});

	useEffect(() => {
		if (currentArea) {
			zoomToElement(currentArea, MAX_SCALE, ANIMATION_DURATION);
		} else if (currentArea === null) {
			resetTransform(ANIMATION_DURATION);
		}
	}, [currentArea, zoomToElement, resetTransform]);

	return (
		<Plane>
			<img
				src={ASSETS.images.map}
				width={MAP_WIDTH}
				height={MAP_HEIGHT}
				alt="Pfaff Landkarte"
			/>
			<AreaPinsOverlay
				positions={areaPositions}
				visible={!showDetailed}
				animated={areaMarkersAnimated}
				onAreaSelected={onAreaSelected}
			/>
			<AppPinsOverlay
				positions={appPositions}
				visible={showDetailed}
				onAppSelected={onAppSelected}
			/>
		</Plane>
	);
};

export default Map;
