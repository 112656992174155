import type { FunctionComponent } from 'react';
import AppScreen from '../molecules/AppScreen.Container';
import { App } from '../../data/Apps';

interface Props {}

const PfaffFunkScreen: FunctionComponent<Props> = () => {
	return <AppScreen app={App.PfaffFunk}></AppScreen>;
};

export default PfaffFunkScreen;
