import find from 'lodash/find';
import { Area } from './Areas';
import type { SvgComponent } from '../assets/Assets';
import ASSETS from '../assets/Assets';

export enum Person {
	BERND = 'bernd',
	SVENJA = 'svenja',
	LISA = 'lisa',
	MARTIN = 'martin',
	LINDA = 'linda'
}

export interface PersonData {
	readonly key: Person;
	readonly name: string;
	readonly image: SvgComponent;
	readonly area: Area | null;
}

const BERND: PersonData = {
	key: Person.BERND,
	name: 'Bernd',
	image: ASSETS.images.bernd,
	area: null
};

const SVENJA: PersonData = {
	key: Person.SVENJA,
	name: 'Svenja',
	image: ASSETS.images.svenja,
	area: Area.Mobility
};

const MARTIN: PersonData = {
	key: Person.MARTIN,
	name: 'Martin',
	image: ASSETS.images.martin,
	area: Area.Energy
};

const LISA: PersonData = {
	key: Person.LISA,
	name: 'Lisa',
	image: ASSETS.images.lisa,
	area: Area.Community
};

const LINDA: PersonData = {
	key: Person.LINDA,
	name: 'Linda',
	image: ASSETS.images.linda,
	area: Area.Simulation
};

type Persons = {
	[key in Person]: PersonData;
};

const PERSONS: Persons = {
	[Person.BERND]: BERND,
	[Person.SVENJA]: SVENJA,
	[Person.MARTIN]: MARTIN,
	[Person.LISA]: LISA,
	[Person.LINDA]: LINDA
};

export const getPersonForArea = (area: Area): PersonData | undefined => {
	return find(PERSONS, ['area', area]);
};

export default PERSONS;
export { BERND };
