import styled from 'styled-components';

const Background = styled.div`
	background-color: ${(props) => props.theme.colors.lightBlue};
	background-image: linear-gradient(
		to right,
		${(props) => props.theme.colors.blue},
		${(props) => props.theme.colors.lightBlue}
	);
	height: 100%;
	width: 100%;
`;

export default Background;
