import { useCallback, useEffect, useState } from 'react';
import type { SvgComponent } from '../assets/Assets';
import ASSETS from '../assets/Assets';
import type { TranslationKey } from '../i18n/i18n';

export enum Area {
	Mobility = 'mobility',
	Energy = 'energy',
	Community = 'community',
	Simulation = 'simulation'
}

interface TourStep {
	text: TranslationKey;
	prev?: TranslationKey;
	next?: TranslationKey;
}

interface Tour {
	steps: TourStep[];
	close: TranslationKey;
}

interface AreaData {
	readonly name: TranslationKey;
	readonly icon: SvgComponent;
	readonly tour: Tour;
}

const MOBILITY: AreaData = {
	name: 'area.mobility.name',
	icon: ASSETS.images.mobility,
	tour: {
		steps: [
			{
				text: 'area.mobility.tour.explanation1',
				next: 'area.mobility.tour.next'
			},
			{
				text: 'area.mobility.tour.explanation2',
				prev: 'area.mobility.tour.back',
				next: 'area.mobility.tour.next'
			},
			{
				text: 'area.mobility.tour.explanation3',
				prev: 'area.mobility.tour.back'
			}
		],
		close: 'area.mobility.tour.closeTour'
	}
};
const ENERGY: AreaData = {
	name: 'area.energy.name',
	icon: ASSETS.images.energy,
	tour: {
		steps: [
			{
				text: 'area.energy.tour.explanation1',
				next: 'area.energy.tour.next'
			},
			{
				text: 'area.energy.tour.explanation2',
				prev: 'area.energy.tour.back'
			}
		],
		close: 'area.energy.tour.closeTour'
	}
};
const COMMUNITY: AreaData = {
	name: 'area.community.name',
	icon: ASSETS.images.community,
	tour: {
		steps: [
			{
				text: 'area.community.tour.explanation1'
			}
		],
		close: 'area.community.tour.closeTour'
	}
};
const SIMULATION: AreaData = {
	name: 'area.simulation.name',
	icon: ASSETS.images.simulation,
	tour: {
		steps: [
			{
				text: 'area.simulation.tour.explanation1',
				next: 'area.simulation.tour.next'
			},
			{
				text: 'area.simulation.tour.explanation2',
				prev: 'area.simulation.tour.back',
				next: 'area.simulation.tour.next'
			},
			{
				text: 'area.simulation.tour.explanation3',
				prev: 'area.simulation.tour.back',
				next: 'area.simulation.tour.next'
			},
			{
				text: 'area.simulation.tour.explanation4',
				prev: 'area.simulation.tour.back',
				next: 'area.simulation.tour.next'
			},
			{
				text: 'area.simulation.tour.explanation5',
				prev: 'area.simulation.tour.back',
				next: 'area.simulation.tour.next'
			},
			{
				text: 'area.simulation.tour.explanation6',
				prev: 'area.simulation.tour.back'
			}
		],
		close: 'area.simulation.tour.closeTour'
	}
};

type Areas = {
	[key in Area]: AreaData;
};

const AREAS: Areas = {
	[Area.Mobility]: MOBILITY,
	[Area.Energy]: ENERGY,
	[Area.Community]: COMMUNITY,
	[Area.Simulation]: SIMULATION
};

export const useTourStep = (tourSteps: TourStep[]) => {
	const [stepIndex, setStepIndex] = useState(0);
	const stepCount = tourSteps.length;
	const currentStepIndex = stepIndex >= stepCount ? stepCount - 1 : stepIndex;
	const isFirst = currentStepIndex === 0;
	const isLast = currentStepIndex === tourSteps.length - 1;

	useEffect(() => {
		setStepIndex(0);
	}, [tourSteps, setStepIndex]);

	const reset = useCallback(() => {
		setStepIndex(0);
	}, [setStepIndex]);

	const prev = useCallback(() => {
		setStepIndex((prevState) => prevState - 1);
	}, [setStepIndex]);

	const next = useCallback(() => {
		setStepIndex((prevState) => prevState + 1);
	}, [setStepIndex]);

	return {
		index: currentStepIndex,
		isFirst,
		isLast,
		step: tourSteps[currentStepIndex],
		onPrev: currentStepIndex - 1 < 0 ? undefined : prev,
		onNext: currentStepIndex + 1 >= stepCount ? undefined : next,
		onReset: reset
	};
};

export default AREAS;
