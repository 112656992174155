import type { FunctionComponent } from 'react';
import AppScreen from '../molecules/AppScreen.Container';
import { App } from '../../data/Apps';

interface Props {}

const SimulatorScreen: FunctionComponent<Props> = () => {
	return <AppScreen app={App.Simulator}></AppScreen>;
};

export default SimulatorScreen;
