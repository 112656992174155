import type { FunctionComponent } from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import ClickAwayListener from 'react-click-away-listener';
import ASSETS from '../../assets/Assets';
import AreaButton from '../atoms/AreaButton';
import { Area } from '../../data/Areas';
import RoundButton from '../atoms/RoundButton';
import Menu from './Menu';

const SidebarSvg = ASSETS.images.sidebar;
const BackSvg = ASSETS.images.back;
const InfoSvg = ASSETS.images.info;

const Bar = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 100%;
	justify-content: center;
	min-width: 117px;
	position: relative;
	width: 117px;
`;

const Top = styled.div`
	flex: 1;
	font-size: 0; // Prevents space underneath
	position: relative;
`;
const Center = styled.div`
	font-size: 0; // Prevents space underneath
	position: relative;
`;
const Bottom = styled.div`
	flex: 1;
	font-size: 0; // Prevents space underneath
	position: relative;
`;
const Fill = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	height: 100%;
	width: 81px;
`;

interface Props {
	currentArea?: Area;
	onAreaSelected: (area: Area) => void;
	onBack?: () => void;
	onIntro: () => void;
	onSponsors: () => void;
}

const Sidebar: FunctionComponent<Props> = ({
	currentArea,
	onAreaSelected,
	onBack,
	onIntro,
	onSponsors
}) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const [referenceElement, setReferenceElement] =
		useState<HTMLButtonElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null
	);
	const { styles, attributes, update } = usePopper(
		referenceElement,
		popperElement,
		{
			placement: 'left-end',
			strategy: 'fixed'
		}
	);

	const handleMenuToggle = useCallback(() => {
		setMenuVisible(!menuVisible);
		update?.();
	}, [menuVisible, setMenuVisible, update]);

	const handleMenuClose = useCallback(
		() => setMenuVisible(false),
		[setMenuVisible]
	);

	const handleIntro = useCallback(() => {
		setMenuVisible(false);
		onIntro();
	}, [onIntro]);

	const handleSponsors = useCallback(() => {
		setMenuVisible(false);
		onSponsors();
	}, [onSponsors]);

	return (
		<Bar>
			<Top>
				<Fill />
				{onBack && (
					<RoundButton onClick={onBack} shadow x="40px" y="30px" size="40px">
						<BackSvg />
					</RoundButton>
				)}
			</Top>
			<Center>
				<SidebarSvg />
				<AreaButton
					area={Area.Mobility}
					onAreaClicked={onAreaSelected}
					activeArea={currentArea}
					x="82px"
					y="calc(532px - 67px)"
				/>
				<AreaButton
					area={Area.Energy}
					onAreaClicked={onAreaSelected}
					activeArea={currentArea}
					x="82px"
					y="calc(532px - 200px)"
				/>
				<AreaButton
					area={Area.Community}
					onAreaClicked={onAreaSelected}
					activeArea={currentArea}
					x="82px"
					y="calc(532px - 333px)"
				/>
				<AreaButton
					area={Area.Simulation}
					onAreaClicked={onAreaSelected}
					activeArea={currentArea}
					x="82px"
					y="calc(532px - 466px)"
				/>
			</Center>
			<Bottom>
				<Fill />
				<ClickAwayListener onClickAway={handleMenuClose}>
					<div>
						<RoundButton
							ref={setReferenceElement}
							onClick={handleMenuToggle}
							shadow
							active={menuVisible}
							x="40px"
							y="40px"
							size="40px"
						>
							<InfoSvg />
						</RoundButton>
						<Menu
							ref={setPopperElement}
							style={styles.popper}
							{...attributes.popper}
							data-show={menuVisible ? true : undefined}
							onIntro={handleIntro}
							onSponsors={handleSponsors}
						/>
					</div>
				</ClickAwayListener>
			</Bottom>
		</Bar>
	);
};

export default Sidebar;
