import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Area } from '../data/Areas';

interface TourInfo {
	closed: boolean;
}

export type ToursSliceState = {
	[area in Area]: TourInfo;
};

const buildInitialState = (): ToursSliceState => {
	return {
		[Area.Mobility]: { closed: false },
		[Area.Energy]: { closed: false },
		[Area.Community]: { closed: false },
		[Area.Simulation]: { closed: false }
	};
};

const toursSlice = createSlice({
	name: 'tours',
	initialState: buildInitialState(),
	reducers: {
		closeTour: (state: ToursSliceState, action: PayloadAction<Area>) => {
			const area = action.payload;
			state[area].closed = true;
		},
		openTour: (state: ToursSliceState, action: PayloadAction<Area>) => {
			const area = action.payload;
			state[area].closed = false;
		},
		resetTours: (state: ToursSliceState) => {
			state = buildInitialState();
		}
	}
});

export const { closeTour, openTour, resetTours } = toursSlice.actions;
export default toursSlice.reducer;
