import type { FunctionComponent } from 'react';
import AppScreen from '../molecules/AppScreen.Container';
import { App } from '../../data/Apps';

interface Props {}

const FishAndTipsScreen: FunctionComponent<Props> = () => {
	return <AppScreen app={App.FishAndTipps} />;
};

export default FishAndTipsScreen;
