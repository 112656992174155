import { connect } from 'react-redux';
import AreaTour from './AreaTour.View';
import type { DispatchProps, OwnProps, StateProps } from './AreaTour.View';
import type { AppDispatch, RootState } from '../../store/Store';
import { closeTour, openTour } from '../../store/ToursSlice';
import type { Area } from '../../data/Areas';

const mapStateToProps = (state: RootState, props: OwnProps): StateProps => ({
	closed: state.tours[props.area].closed
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
	onOpen: (area: Area) => dispatch(openTour(area)),
	onClose: (area: Area) => dispatch(closeTour(area))
});

export default connect(mapStateToProps, mapDispatchToProps)(AreaTour);
