import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import type { Link } from '../../data/Apps';
import LinkButton from '../atoms/LinkButton';

const Block = styled.div`
	align-items: stretch;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 0.5em;
	width: 10em;
`;

interface Props {
	links?: Link[];
}

const Links: FunctionComponent<Props> = ({ links = [] }) => {
	const { t } = useTranslation();

	const linkComponents = useMemo(() => {
		return map(links, (link, index) => {
			return (
				<LinkButton key={index} href={link.url}>
					{t(link.text)}
				</LinkButton>
			);
		});
	}, [t, links]);

	return <Block>{linkComponents}</Block>;
};

export default Links;
