import { useCallback, useMemo } from 'react';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';
import map from 'lodash/map';

const OptionContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
`;
const Radio = styled.input.attrs({ type: 'radio' })``;

interface OptionProps {
	id: string;
	name: string;
	title: string;
	value: string;
	selected: boolean;
	onChange: (value: string, name: string) => void;
}

const Option: FunctionComponent<OptionProps> = ({
	id,
	name,
	title,
	value,
	selected,
	onChange
}) => {
	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;
			onChange(value, name);
		},
		[name, onChange]
	);

	return (
		<OptionContainer>
			<Radio
				id={id}
				name={name}
				value={value}
				onChange={handleChange}
				checked={selected}
			></Radio>
			<label htmlFor={id}>{title}</label>
		</OptionContainer>
	);
};

const OptionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
	gap: 2em;
`;

export interface Item {
	readonly title: string;
	readonly value: string;
}

interface OptionsProps {
	name: string;
	items: Item[];
	selectedValue?: string;
	onChange: (value: string, name: string) => void;
}

const Options: FunctionComponent<OptionsProps> = ({
	name,
	items,
	selectedValue,
	onChange
}) => {
	const itemComponents = useMemo(() => {
		return map(items, (item) => (
			<Option
				key={item.value}
				id={'option_' + name + '_' + item.value}
				name={name}
				title={item.title}
				value={item.value}
				selected={selectedValue === item.value}
				onChange={onChange}
			/>
		));
	}, [name, items, selectedValue, onChange]);

	return <OptionsContainer>{itemComponents}</OptionsContainer>;
};

export default Options;
