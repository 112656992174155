import { useCallback, useMemo, useState } from 'react';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';
import size from 'lodash/size';
import PersonSpeechOverlay from '../molecules/PersonSpeechOverlay';
import SpeechBubble from '../molecules/SpeechBubble';
import PersonSpeechBubble from '../molecules/PersonSpeechBubble';
import Survey from './Survey';
import { Person } from '../../data/Persons';
import type { App } from '../../data/Apps';
import style from '../molecules/PersonSpeechBubble.module.css';

const StyledSpeechBubble = styled(SpeechBubble)`
	margin: 1em;
`;

export interface StateProps {
	finished: boolean;
	sending: boolean;
	openAppSurveys: App[];
}

export interface DispatchProps {
	onFinish: (data: object) => void;
	onFinishApp: (app: App) => void;
}

export interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

const SurveyTour: FunctionComponent<Props> = ({
	finished,
	sending,
	openAppSurveys,
	onFinish,
	onFinishApp
}) => {
	const [open, setOpen] = useState(false);

	const hasOpenAppSurveys = useMemo(
		() => size(openAppSurveys) > 0,
		[openAppSurveys]
	);

	const handleOpen = useCallback(() => {
		setOpen(true);
	}, [setOpen]);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	const handleFinish = useCallback(
		(data: object) => {
			onFinish(data);
		},
		[onFinish]
	);

	if (finished /*&& !hasOpenAppSurveys*/) {
		return <></>;
	}

	return (
		<PersonSpeechOverlay
			person={Person.BERND}
			closed={!open}
			onClose={handleClose}
			onOpen={handleOpen}
		>
			<PersonSpeechBubble className={style.fadeInBottomLeft}>
				<StyledSpeechBubble>
					<Survey
						apps={openAppSurveys}
						sending={sending}
						onFinish={handleFinish}
					/>
				</StyledSpeechBubble>
			</PersonSpeechBubble>
		</PersonSpeechOverlay>
	);
};

export default SurveyTour;
