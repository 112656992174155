import styled from 'styled-components';
import SpeechBubble from './SpeechBubble';

const PersonSpeechBubble = styled(SpeechBubble)`
	background-color: ${(props) => props.theme.colors.white};
	border: 1px solid ${(props) => props.theme.colors.darkGray};
	bottom: 8vmin;
	left: calc(120px + 24vmin - 12vmin);
	max-width: 30em;
	position: absolute;
`;

export default PersonSpeechBubble;
