import { useCallback } from 'react';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
	width: 100%;
	background: ${(props) => props.theme.colors.white};
	border-radius: 0.4em;
	border: 1px solid ${(props) => props.theme.colors.gray};
	font-family: inherit;
	font-size: inherit;
	padding: 0.25em;
`;

type TextareaProps = Omit<
	React.TextareaHTMLAttributes<HTMLTextAreaElement>,
	'onChange'
> & {
	onChange: (value: string) => void;
};

const Textarea: FunctionComponent<TextareaProps> = ({
	onChange,
	...otherProps
}) => {
	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {
			onChange(event.currentTarget.value);
		},
		[onChange]
	);

	return <StyledTextarea rows={4} onChange={handleChange} {...otherProps} />;
};

export default Textarea;
