import { connect } from 'react-redux';
import AppScreen from './AppScreen.View';
import type { DispatchProps, OwnProps, StateProps } from './AppScreen.View';
import type { AppDispatch } from '../../store/Store';
import { viewApp } from '../../store/AppsSlice';
import type { App } from '../../data/Apps';

const mapStateToProps = (): StateProps => {
	return {};
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
	onViewed: (app: App) => dispatch(viewApp(app))
});

export default connect<StateProps, DispatchProps, OwnProps>(
	mapStateToProps,
	mapDispatchToProps
)(AppScreen);
