import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { App } from '../data/Apps';

const SURVEY_URL: string = process.env.REACT_APP_SURVEY_URL as string;

interface AppInfo {
	finished: boolean;
}

type AppsState = {
	[app in App]: AppInfo;
};

export type SurveySliceState = {
	sending: boolean;
	finished: boolean;
	apps: AppsState;
};

const INITIAL_STATE: SurveySliceState = {
	sending: false,
	finished: false,
	apps: {
		[App.FishAndTipps]: {
			finished: false
		},
		[App.MiniLautern]: {
			finished: false
		},
		[App.PfaffFunk]: {
			finished: false
		},
		[App.Simulator]: {
			finished: false
		}
	}
};

export const finishSurvey = createAsyncThunk(
	'postSurveyResult',
	async (data: object) => {
		await fetch(SURVEY_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});
		return data;
	}
);

const surveySlice = createSlice({
	name: 'survey',
	initialState: INITIAL_STATE,
	reducers: {
		finishAppSurvey: (state: SurveySliceState, action: PayloadAction<App>) => {
			const app = action.payload;
			state.apps[app].finished = true;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(finishSurvey.pending, (state: SurveySliceState) => {
				state.sending = true;
			})
			.addCase(finishSurvey.fulfilled, (state: SurveySliceState) => {
				state.sending = false;
				state.finished = true;
			})
			.addCase(finishSurvey.rejected, (state: SurveySliceState) => {
				state.sending = false;
			});
	}
});

export const { finishAppSurvey } = surveySlice.actions;
export default surveySlice.reducer;
