import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import head from 'lodash/head';
import filter from 'lodash/filter';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import Map, {
	DETAIL_SCALE,
	MAP_HEIGHT,
	MAP_WIDTH,
	MAX_SCALE,
	MIN_SCALE
} from './Map';
import type { AreaPosition } from './AreaPinsOverlay';
import type { AppPosition } from './AppPinsOverlay';
import { Area } from '../../data/Areas';
import { App } from '../../data/Apps';

const AREA_POSITIONS: AreaPosition[] = [
	{
		area: Area.Mobility,
		x: 920,
		y: 260
	},
	{
		area: Area.Energy,
		x: 1260,
		y: 460
	},
	{
		area: Area.Community,
		x: 900,
		y: 680
	},
	{
		area: Area.Simulation,
		x: 540,
		y: 480
	}
];

const APP_ICON_OFFSET_X = 40;
const APP_ICON_OFFSET_Y = 80;

const APP_POSITIONS: AppPosition[] = [
	{
		app: App.MiniLautern,
		x: 920 + APP_ICON_OFFSET_X,
		y: 260 + APP_ICON_OFFSET_Y
	},
	{
		app: App.FishAndTipps,
		x: 1260 + APP_ICON_OFFSET_X,
		y: 460 + APP_ICON_OFFSET_Y
	},
	{
		app: App.PfaffFunk,
		x: 900 + APP_ICON_OFFSET_X,
		y: 680 + APP_ICON_OFFSET_Y
	},
	{
		app: App.Simulator,
		x: 540 + APP_ICON_OFFSET_X,
		y: 480 + APP_ICON_OFFSET_Y
	}
];

const distance = (x1: number, y1: number, x2: number, y2: number): number => {
	const result = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
	//	console.log('Distance: [', x1, y1, '] - [', x2, y2, '] => ', result);
	return result;
};

const WHEEL_CONFIG = { step: 0.1 };

interface Props {
	/**
	 * When the value changes, the specified area is focused.
	 * A null value centers the entire map.
	 */
	currentArea?: Area | null;
	areaMarkersAnimated: boolean;
	onAreaSelected: (area: Area) => void;
	onFocussedAreaChanged: (area?: Area) => void;
	onAppSelected: (app: App) => void;
}

const MapView: FunctionComponent<Props> = ({
	currentArea,
	areaMarkersAnimated,
	onAreaSelected,
	onFocussedAreaChanged,
	onAppSelected
}) => {
	const [focusedArea, setFocusedArea] = useState(currentArea);

	useEffect(() => {
		if (currentArea) {
			setFocusedArea(currentArea);
		} else if (currentArea === null) {
			setFocusedArea(undefined);
		}
	}, [currentArea, setFocusedArea]);

	const handleMapInteraction = useCallback(
		(ref: ReactZoomPanPinchRef): void => {
			const { scale, positionX, positionY } = ref.state;
			const { bounds } = ref.instance;
			if (bounds) {
				if (scale > DETAIL_SCALE) {
					const width = bounds.maxPositionX - bounds.minPositionX;
					const height = bounds.maxPositionY - bounds.minPositionY;
					const deltaCenterX = (width / 2 + positionX) / scale;
					const deltaCenterY = (height / 2 + positionY) / scale;
					const focusX = Math.abs(MAP_WIDTH / 2 - deltaCenterX);
					const focusY = Math.abs(MAP_HEIGHT / 2 - deltaCenterY);
					const distanceToFocus = (areaPosition: AreaPosition): number => {
						return distance(
							focusX,
							focusY,
							areaPosition.x,
							MAP_HEIGHT - areaPosition.y
						);
					};
					const maxDistance = Math.min(MAP_WIDTH, MAP_HEIGHT) / 4;
					const nearAreaPositions = filter(
						AREA_POSITIONS,
						(areaPosition) => distanceToFocus(areaPosition) < maxDistance
					);
					const nearestAreaPosition = head(
						sortBy(nearAreaPositions, (areaPosition) =>
							distanceToFocus(areaPosition)
						)
					);
					const nearestArea = nearestAreaPosition?.area;
					if (nearestArea !== focusedArea) {
						setFocusedArea(nearestArea);
						onFocussedAreaChanged(nearestArea);
					}
				} else {
					setFocusedArea(undefined);
					onFocussedAreaChanged(undefined);
				}
			}
		},
		[focusedArea, setFocusedArea, onFocussedAreaChanged]
	);

	return (
		<TransformWrapper
			minScale={MIN_SCALE}
			maxScale={MAX_SCALE}
			wheel={WHEEL_CONFIG}
			centerOnInit
			centerZoomedOut
			alignmentAnimation={{ disabled: true, sizeX: 0, sizeY: 0 }}
			onPanningStop={handleMapInteraction}
			onPinchingStop={handleMapInteraction}
			onZoomStop={handleMapInteraction}
			onWheelStop={handleMapInteraction}
		>
			<TransformComponent
				wrapperStyle={{
					height: '100%',
					position: 'relative',
					width: '100%',
					overflow: 'visible'
				}}
			>
				<Map
					currentArea={currentArea}
					areaPositions={AREA_POSITIONS}
					areaMarkersAnimated={areaMarkersAnimated}
					onAreaSelected={onAreaSelected}
					appPositions={APP_POSITIONS}
					onAppSelected={onAppSelected}
				/>
			</TransformComponent>
		</TransformWrapper>
	);
};

export default MapView;
