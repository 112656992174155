import type { FunctionComponent } from 'react';
import styled from 'styled-components';
import type { TranslationKey } from '../../i18n/i18n';
import TranslatedText from '../atoms/TranslatedText';

const Container = styled.div`
	border-bottom-right-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	font: ${(props) => props.theme.fonts.speechBubble};
	gap: 1em;
	height: 100%;
	justify-content: space-between;
	padding: 2em;
	& > :first-child {
		flex: 1 1 auto;
	}
`;

export const Actions = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 1em;
	justify-content: flex-end;
`;

export const Action = styled.div`
	align-items: stretch;
	display: flex;
	flex: 1;
	flex-direction: column;
`;

export interface Props {
	className?: string;
	textKey: TranslationKey;
	headerComponent?: React.ReactNode;
	firstComponent?: React.ReactNode;
	lastComponent?: React.ReactNode;
}

const TextSpeechBubble: FunctionComponent<Props> = ({
	className,
	textKey,
	headerComponent,
	firstComponent,
	lastComponent
}) => {
	return (
		<Container className={className}>
			{headerComponent}
			<TranslatedText translationKey={textKey} />
			<Actions>
				<Action>{firstComponent}</Action>
				<Action>{lastComponent}</Action>
			</Actions>
		</Container>
	);
};

export default TextSpeechBubble;
