import type { FunctionComponent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import Background from './components/atoms/Background';
import Sidebar from './components/molecules/Sidebar';
import MapView from './components/molecules/MapView';
import Title from './components/atoms/Title';
import AppView from './components/molecules/AppView';
import IntroDialog from './components/organisms/IntroDialog';
import MapIntro from './components/organisms/MapIntro';
import ToursDialog from './components/organisms/ToursDialog';
import SponsorsDialog from './components/organisms/SponsorsDialog';
import type { Area } from './data/Areas';
import type { App as AppId } from './data/Apps';
import AreaTour from './components/organisms/AreaTour.Container';
import SurveyTour from './components/organisms/SurveyTour.Container';

const Overlay = styled.div`
	align-items: stretch;
	bottom: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	& > *:first-child {
		align-self: flex-end;
		z-index: 10;
	}
	& > *:last-child {
		flex-grow: 1;
	}
`;

const App: FunctionComponent = () => {
	// Area last selected via click on sidebar button or map pin
	const [selectedArea, setSelectedArea] = useState<Area | null | undefined>(
		undefined
	);
	// Area currently focussed via map pan and zoom
	const [focussedArea, setFocussedArea] = useState<Area | undefined>();
	const [currentApp, setCurrentApp] = useState<AppId | null>(null);
	const [introVisible, setIntroVisible] = useState(true);
	const [mapIntroVisible, setMapIntroVisible] = useState(false);
	const [toursVisible, setToursVisible] = useState(false);
	const [sponsorsVisible, setSponsorsVisible] = useState(false);

	const hideIntro = useCallback(() => {
		setIntroVisible(false);
		setMapIntroVisible(false);
		setToursVisible(false);
	}, []);

	const handleIntroSkip = useCallback(() => {
		setIntroVisible(false);
	}, []);

	const handleIntroClose = useCallback(() => {
		setIntroVisible(false);
		setMapIntroVisible(true);
	}, []);

	const handleMapIntroNext = useCallback(() => {
		setMapIntroVisible(false);
		setToursVisible(true);
	}, []);

	const handleIntroRestart = useCallback(() => {
		setSelectedArea(null);
		setFocussedArea(undefined);
		setCurrentApp(null);
		setIntroVisible(true);
		setMapIntroVisible(false);
		setToursVisible(false);
	}, []);

	const handleToursSkip = useCallback(() => {
		setToursVisible(false);
	}, []);

	const handleTour = useCallback((area: Area) => {
		setToursVisible(false);
		setSelectedArea(area);
		setFocussedArea(area);
	}, []);

	const handleSelectedAreaChange = useCallback(
		(area?: Area) => {
			hideIntro();
			setSelectedArea(!!area ? area : null);
			setFocussedArea(area);
			setCurrentApp(null);
		},
		[hideIntro]
	);
	const handleFocussedAreaChange = useCallback(
		(area?: Area) => {
			hideIntro();
			setSelectedArea(undefined);
			setFocussedArea(area);
			setCurrentApp(null);
		},
		[hideIntro]
	);

	const isBackAvailable = useMemo(
		() => focussedArea || currentApp,
		[focussedArea, currentApp]
	);
	const handleBack = useCallback(() => {
		if (currentApp) {
			setCurrentApp(null);
		} else {
			setSelectedArea(null);
			setFocussedArea(undefined);
		}
	}, [currentApp, setSelectedArea]);

	const handleAppSelected = useCallback(
		(app: AppId) => {
			setCurrentApp(app);
		},
		[setCurrentApp]
	);

	const handleSponsorsShow = useCallback(() => {
		setSponsorsVisible(true);
	}, [setSponsorsVisible]);

	const handleSponsorsClose = useCallback(() => {
		setSponsorsVisible(false);
	}, [setSponsorsVisible]);

	return (
		<>
			<Background />
			<Overlay>
				<Sidebar
					currentArea={focussedArea}
					onAreaSelected={handleSelectedAreaChange}
					onBack={isBackAvailable ? handleBack : undefined}
					onIntro={handleIntroRestart}
					onSponsors={handleSponsorsShow}
				/>
				<MapView
					currentArea={selectedArea}
					areaMarkersAnimated={
						!introVisible && !mapIntroVisible && !toursVisible
					}
					onAreaSelected={handleSelectedAreaChange}
					onAppSelected={handleAppSelected}
					onFocussedAreaChanged={handleFocussedAreaChange}
				/>
				<AppView app={currentApp} />
				{!currentApp && <Title />}
			</Overlay>
			<IntroDialog
				visible={introVisible}
				onSkip={handleIntroSkip}
				onClose={handleIntroClose}
			/>
			<MapIntro visible={mapIntroVisible} onNext={handleMapIntroNext} />
			<ToursDialog
				visible={toursVisible}
				onSkip={handleToursSkip}
				onTour={handleTour}
			/>
			{focussedArea && !currentApp && <AreaTour area={focussedArea} />}
			{!introVisible &&
				!mapIntroVisible &&
				!toursVisible &&
				!focussedArea &&
				!currentApp && <SurveyTour />}
			<SponsorsDialog visible={sponsorsVisible} onClose={handleSponsorsClose} />
		</>
	);
};

export default App;
