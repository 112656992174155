import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import size from 'lodash/size';
import isArray from 'lodash/isArray';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import type { Url } from '../../utils/types';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Images.css';

const Img = styled.img`
	max-height: 100%;
	max-width: 100%;
	min-width: 0;
	object-fit: scale-down;
`;

const ImgSet = styled.div`
	align-items: center;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	height: 100%;
	max-height: 100%;
	max-width: 100%;
	padding-bottom: 32px;
	width: 100%;
	padding-left: 40px;
	padding-right: 40px;
`;

const asImgSet = (urls: Url | Url[]) => {
	if (isArray(urls)) {
		return map(urls, (url, index) => <Img key={index} src={url} />);
	} else {
		return <Img src={urls} />;
	}
};

interface Props {
	className?: string;
	urls?: (Url | Url[])[];
}

const Images: FunctionComponent<Props> = ({ className, urls }) => {
	const multiplePages = useMemo(() => size(urls) !== 1, [urls]);

	const slides = useMemo(
		() =>
			map(urls, (slideUrls, index) => (
				<SwiperSlide key={index}>
					<ImgSet>{asImgSet(slideUrls)}</ImgSet>
				</SwiperSlide>
			)),
		[urls]
	);

	return (
		<Swiper
			className={className}
			modules={[EffectFade, Autoplay, Pagination, Navigation]}
			effect="fade"
			fadeEffect={{ crossFade: true }}
			speed={1_000}
			autoplay={
				multiplePages && {
					delay: 10_000,
					pauseOnMouseEnter: false,
					disableOnInteraction: true
				}
			}
			pagination={{
				dynamicBullets: true,
				clickable: true
			}}
			navigation={true}
		>
			{slides}
		</Swiper>
	);
};

export default Images;
