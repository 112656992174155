import type { FunctionComponent } from 'react';
import TextSpeechBubble from './TextSpeechBubble';
import type { Props } from './TextSpeechBubble';
import PersonSpeechBubble from './PersonSpeechBubble';
import style from './PersonSpeechBubble.module.css';

const PersonTextSpeechBubble: FunctionComponent<Props> = (props) => {
	return (
		<PersonSpeechBubble className={style.fadeInBottomLeft}>
			<TextSpeechBubble {...props} />
		</PersonSpeechBubble>
	);
};

export default PersonTextSpeechBubble;
