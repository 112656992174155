import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { App } from '../data/Apps';

interface AppInfo {
	viewed: boolean;
}

export type AppsSliceState = {
	[app in App]: AppInfo;
};

const buildInitialState = (): AppsSliceState => {
	return {
		[App.FishAndTipps]: {
			viewed: false
		},
		[App.MiniLautern]: {
			viewed: false
		},
		[App.PfaffFunk]: {
			viewed: false
		},
		[App.Simulator]: {
			viewed: false
		}
	};
};

const appsSlice = createSlice({
	name: 'apps',
	initialState: buildInitialState(),
	reducers: {
		viewApp: (state: AppsSliceState, action: PayloadAction<App>) => {
			const app = action.payload;
			state[app].viewed = true;
		}
	}
});

export const { viewApp } = appsSlice.actions;
export default appsSlice.reducer;
