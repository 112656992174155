import styled from 'styled-components';
import type { Property } from 'csstype';

export interface Props {
	disabled?: boolean;
	primary?: boolean;
	width?: Property.Width;
	className?: string;
}

const BaseRectangularButton = styled.button<Props>`
	background-color: ${(props) =>
		props.primary ?? false ? props.theme.colors.red : props.theme.colors.white};
	border: ${(props) => '1px solid ' + props.theme.colors.grayBlue};
	border-radius: 99999px;
	box-shadow: ${(props) => '0 3px 6px ' + props.theme.colors.shadowDark};
	color: ${(props) =>
		props.primary ?? false ? props.theme.colors.white : props.theme.colors.red};
	cursor: pointer;
	font: ${(props) => props.theme.fonts.button};
	margin-bottom: 6px;
	margin-left: 3px;
	margin-right: 3px;
	padding-bottom: 0.1em;
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 0.1em;
	text-align: center;
	text-decoration: none;
	user-select: none;
	white-space: pre;
	width: ${(props) => props.width};
	&:disabled {
		background-color: ${(props) => props.theme.colors.gray};
		color: ${(props) => props.theme.colors.white};
		cursor: default;
	}
`;

export default BaseRectangularButton;
