import type { CSSProperties, PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LinkButton from '../atoms/LinkButton';
import RectangularButton from '../atoms/RectangularButton';

const Background = styled.div`
	align-items: flex-end;
	position: absolute;
	background-color: ${(props) => props.theme.colors.white};
	border-bottom-right-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	display: none;
	flex-direction: column;
	flex-wrap: nowrap;
	font: ${(props) => props.theme.fonts.text};
	gap: 0.2em;
	padding: 0.8em;
	padding-left: 3em;
	&[data-show] {
		display: flex;
	}
`;

const Caption = styled.div`
	font: ${(props) => props.theme.fonts.default};
	font-weight: bold;
	margin-bottom: 1em;
`;

interface Props {
	style?: CSSProperties | undefined;
	onIntro: () => void;
	onSponsors: () => void;
}

const Menu = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
	({ onIntro, onSponsors, ...menuProps }, ref) => {
		const { t } = useTranslation();

		return (
			<Background ref={ref} {...menuProps}>
				<Caption>{t('menu.additionalInfo')}</Caption>
				<RectangularButton primary onClick={onIntro}>
					{t('menu.intro')}
				</RectangularButton>
				<LinkButton href="https://oc.iese.de/index.php/s/oBiC3Ilyf0eWalM">
					{t('menu.iktReport')}
				</LinkButton>
				<LinkButton href="https://www.iese.fraunhofer.de/de/impressum.html">
					{t('menu.imprint')}
				</LinkButton>
				<LinkButton href="https://dsi.informationssicherheit.fraunhofer.de/de/dsi/www.pfaff-landkarte.de">
					{t('menu.privacyNotice')}
				</LinkButton>
				<RectangularButton primary onClick={onSponsors}>
					{t('menu.sponsors')}
				</RectangularButton>
			</Background>
		);
	}
);

export default Menu;
