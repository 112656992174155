import type { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import RectangularButton from '../atoms/RectangularButton';
import Dialog, { Actions, Title } from '../molecules/Dialog';
import type { Props as DialogProps } from '../molecules/Dialog';
import ASSETS from '../../assets/Assets';

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 1em 0;
	row-gap: 1em;
`;

const Logo = styled.img`
	max-height: 60vh;
	max-width: 60vw;
`;

const Link = styled.a.attrs({ target: '_blank' })`
	color: ${(props) => props.theme.colors.red};
	font: ${(props) => props.theme.fonts.default};
	font-weight: bold;
`;

interface Props extends DialogProps {
	onClose: () => void;
}

const SponsorsDialog: FunctionComponent<Props> = ({
	onClose,
	...dialogProps
}) => {
	const { t } = useTranslation();

	return (
		<Dialog {...dialogProps}>
			<Title>{t('sponsors.title')}</Title>
			<Content>
				<Logo src={ASSETS.images.sponsors} alt="BMWK & BMBF" />
				<Link href="https://pfaff-reallabor.de/" title="pfaff-reallabor.de">
					Pfaff Reallabor
				</Link>
			</Content>
			<Actions>
				<RectangularButton primary width="10em" onClick={onClose}>
					{t('sponsors.close')}
				</RectangularButton>
			</Actions>
		</Dialog>
	);
};

export default SponsorsDialog;
