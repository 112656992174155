import type { FunctionComponent, PropsWithChildren } from 'react';
import type { Props as BaseRectangularButtonProps } from './BaseRectangularButton';
import BaseRectangularButton from './BaseRectangularButton';

export interface Props extends BaseRectangularButtonProps {
	onClick?: () => void;
	className?: string;
}

const RectangularButton: FunctionComponent<PropsWithChildren<Props>> = ({
	children,
	onClick,
	...baseProps
}) => {
	return (
		<BaseRectangularButton onClick={onClick} {...baseProps}>
			{children}
		</BaseRectangularButton>
	);
};

export default RectangularButton;
