import type { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import type { AppData } from '../../data/Apps';

const Block = styled.div``;

const Title = styled.span`
	font: ${(props) => props.theme.fonts.title};
`;

const Description = styled.p``;

interface Props {
	data: AppData;
}

const AppDescription: FunctionComponent<Props> = ({ data }) => {
	const { t } = useTranslation();

	return (
		<Block>
			<Title>{t(data.title)}</Title>
			<Description>{t(data.description)}</Description>
		</Block>
	);
};

export default AppDescription;
