import type { CSSProperties, FunctionComponent } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import type { App } from '../../data/Apps';
import APPS from '../../data/Apps';
import styles from './AppPin.module.css';

interface ContainerProps {
	visible: boolean;
	x: number;
	y: number;
}

const SIZE = 60;

const Container = styled.div<ContainerProps>`
	bottom: ${(props) => props.y - SIZE / 2}px;
	cursor: pointer;
	height: ${SIZE}px;
	left: ${(props) => props.x - SIZE / 2}px;
	position: absolute;
	visibility: ${(props) => (props.visible ? undefined : 'hidden')};
	width: ${SIZE}px;
`;

const LOGO_STYLE: CSSProperties = {
	margin: '5%'
};

interface Props {
	app: App;
	visible: boolean;
	x: number;
	y: number;
	onClick: () => void;
}

const AppPin: FunctionComponent<Props> = ({ app, visible, x, y, onClick }) => {
	const { t } = useTranslation();

	const Logo = useMemo(() => APPS[app].images.icon, [app]);

	return (
		<Container
			className={styles.pulse}
			visible={visible}
			x={x}
			y={y}
			onClick={onClick}
			title={t(APPS[app].name)}
		>
			<Logo style={LOGO_STYLE} width="100%" height="100%" />
		</Container>
	);
};

export default AppPin;
