import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import type { TranslationKey } from '../../i18n/i18n';

const Content = styled.div`
	overflow-y: auto;
`;

const Paragraph = styled.p``;

interface Props {
	className?: string;
	translationKey: TranslationKey;
}

const TranslatedText: FunctionComponent<Props> = ({
	className,
	translationKey
}) => {
	const { t } = useTranslation();

	const paragraphs = useMemo(() => {
		const lines = t(translationKey, { returnObjects: true });
		return map(lines, (text, index) => (
			<Paragraph key={index}>{text}</Paragraph>
		));
	}, [t, translationKey]);

	return <Content className={className}>{paragraphs}</Content>;
};

export default TranslatedText;
