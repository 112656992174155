import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';
import type { Property } from 'csstype';

interface ButtonProps {
	shadow: boolean;
	highlighted: boolean;
	x?: Property.Left;
	y?: Property.Bottom;
	size: Property.Width;
}

const Button = styled.button<ButtonProps>`
	background-color: ${(props) =>
		props.highlighted ? props.theme.colors.red : props.theme.colors.white};
	border: ${(props) =>
		props.shadow ? '1px solid ' + props.theme.colors.gray : 'none'};
	border-radius: 99999px;
	box-shadow: ${(props) =>
		props.shadow ? '0 3px 6px ' + props.theme.colors.shadowLight : undefined};
	color: ${(props) =>
		props.highlighted ? props.theme.colors.white : props.theme.colors.red};
	cursor: pointer;
	position: ${(props) => (props.x || props.y ? 'absolute' : 'relative')};
	left: calc(${(props) => props.x} - (${(props) => props.size} / 2));
	bottom: calc(${(props) => props.y} - (${(props) => props.size} / 2));
	width: ${(props) => props.size};
	height: ${(props) => props.size};
	& svg {
		height: 60%;
		left: 50%;
		max-height: 60%;
		max-width: 60%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 60%;
	}
`;

export interface Props {
	shadow?: boolean;
	active?: boolean;
	onClick?: () => void;
	x?: Property.Left;
	y?: Property.Bottom;
	size: Property.Width;
}

const RoundButton = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
	(
		{ children, shadow = false, active = false, onClick, ...buttonProps },
		ref
	) => {
		return (
			<Button
				ref={ref}
				shadow={shadow}
				highlighted={active}
				onClick={onClick}
				{...buttonProps}
			>
				{children}
			</Button>
		);
	}
);

export default RoundButton;
