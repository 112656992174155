import styled from 'styled-components';
import SpeechBubble from './SpeechBubble';

const AppSpeechBubble = styled(SpeechBubble)`
	background-color: ${(props) => props.theme.colors.white};
	border: 1px solid ${(props) => props.theme.colors.darkGray};
	height: 100%;
	scrollbar-color: ${(props) => props.theme.colors.red}
		${(props) => props.theme.colors.lightRed};
`;

export default AppSpeechBubble;
