import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import { useTranslation } from 'react-i18next';
import type { Props as DialogProps } from '../molecules/Dialog';
import Dialog, { Title } from '../molecules/Dialog';
import RectangularButton from '../atoms/RectangularButton';
import TourInfo from '../molecules/TourInfo';
import PERSONS, { BERND, Person } from '../../data/Persons';
import type { Area } from '../../data/Areas';

const BerndImage = BERND.image;

const BerndText = styled.p`
	font-weight: bold;
	max-width: 50%;
`;

const Overview = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	font: ${(props) => props.theme.fonts.text};
`;

const Avatar = styled.div`
	height: 23.2vmin;
	width: 23.2vmin;
`;

const Tours = styled.div`
	column-gap: 2em;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-top: 2em;
`;

const Description = styled.p`
	flex-grow: 1;
	font: ${(props) => props.theme.fonts.text};
	text-align: center;
`;

const SkipButton = styled(RectangularButton)`
	position: absolute;
	right: 1em;
	top: 1em;
`;

interface Props extends DialogProps {
	onSkip: () => void;
	onTour: (area: Area) => void;
}

const ToursDialog: FunctionComponent<Props> = ({
	onSkip,
	onTour,
	...dialogProps
}) => {
	const { t } = useTranslation();

	const tours = useMemo(() => {
		return map(
			pickBy(PERSONS, (value) => Boolean(value.area)),
			(_, personKey) => {
				const person = personKey as Person;
				const area = PERSONS[person].area;
				return (
					<TourInfo key={person} person={person}>
						<Description>{t(`tours.${person}.description`)}</Description>
						{area && (
							<RectangularButton primary onClick={() => onTour(area)}>
								{t(`tours.${person}.start`)}
							</RectangularButton>
						)}
					</TourInfo>
				);
			}
		);
	}, [t, onTour]);

	return (
		<Dialog {...dialogProps}>
			<Title>{t('tours.title')}</Title>
			<SkipButton onClick={onSkip}>{t('tours.skip')}</SkipButton>
			<Overview>
				<Avatar>
					<BerndImage />
				</Avatar>
				<BerndText>{t(`tours.${Person.BERND}.description`)}</BerndText>
			</Overview>
			<Tours>{tours}</Tours>
		</Dialog>
	);
};

export default ToursDialog;
