import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import SurveyTour from './SurveyTour.View';
import type { DispatchProps, StateProps } from './SurveyTour.View';
import type { AppDispatch, RootState } from '../../store/Store';
import { finishAppSurvey, finishSurvey } from '../../store/SurveySlice';
import type { AppsSliceState } from '../../store/AppsSlice';
import type { App } from '../../data/Apps';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

const selectAppSurveys = (state: RootState) => state.survey.apps;
const selectApps = (state: RootState): AppsSliceState => state.apps;

const selectOpenAppSurveys = createSelector(
	selectAppSurveys,
	selectApps,
	(appSurveys, apps): App[] => {
		return keys(
			pickBy(
				appSurveys,
				(appSurveyInfo, app) =>
					apps[app as App].viewed && !appSurveyInfo.finished
			)
		) as App[];
	}
);

const mapStateToProps = (state: RootState): StateProps => {
	return {
		openAppSurveys: selectOpenAppSurveys(state),
		finished: state.survey.finished,
		sending: state.survey.sending
	};
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
	onFinish: (data: object) => dispatch(finishSurvey(data)),
	onFinishApp: (app: App) => dispatch(finishAppSurvey(app))
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTour);
