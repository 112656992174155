import type { FunctionComponent } from 'react';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import RectangularButton from '../atoms/RectangularButton';
import PersonSpeechOverlay from '../molecules/PersonSpeechOverlay';
import PersonTextSpeechBubble from '../molecules/PersonTextSpeechBubble';
import { getPersonForArea } from '../../data/Persons';
import type { Area } from '../../data/Areas';
import AREAS, { useTourStep } from '../../data/Areas';

const Header = styled.span`
	color: ${(props) => props.theme.colors.red};
	font: ${(props) => props.theme.fonts.title};
	font-weight: bold;
`;

export interface StateProps {
	closed: boolean;
}

export interface DispatchProps {
	onOpen: (area: Area) => void;
	onClose: (area: Area) => void;
}

export interface OwnProps {
	area: Area;
}

type Props = StateProps & DispatchProps & OwnProps;

const AreaTour: FunctionComponent<Props> = ({
	area,
	closed,
	onOpen,
	onClose
}) => {
	const { t } = useTranslation();

	const person = useMemo(() => getPersonForArea(area)?.key, [area]);
	const areaData = useMemo(() => AREAS[area], [area]);
	const tour = areaData.tour;
	const { step, isLast, onPrev, onNext, onReset } = useTourStep(tour.steps);

	const handleOpen = useCallback(() => {
		onReset();
		onOpen(area);
	}, [area, onOpen, onReset]);

	const handleClose = useCallback(() => {
		onClose(area);
	}, [area, onClose]);

	const header = useMemo(
		() => <Header>{t(areaData.name)}</Header>,
		[t, areaData]
	);

	const prevButton = useMemo(() => {
		if (onPrev && step.prev) {
			return (
				<RectangularButton key="prev" onClick={onPrev}>
					{t(step.prev)}
				</RectangularButton>
			);
		}
	}, [step, t, onPrev]);

	const nextButton = useMemo(() => {
		if (onNext && step.next) {
			return (
				<RectangularButton key="next" primary onClick={onNext}>
					{t(step.next)}
				</RectangularButton>
			);
		}
	}, [step, t, onNext]);

	const closeButton = useMemo(
		() => (
			<RectangularButton key="close" primary onClick={handleClose}>
				{t(tour.close)}
			</RectangularButton>
		),
		[t, tour, handleClose]
	);

	if (!person) {
		return null;
	}

	return (
		<PersonSpeechOverlay
			person={person}
			closed={closed}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<PersonTextSpeechBubble
				textKey={step.text}
				headerComponent={header}
				firstComponent={prevButton}
				lastComponent={isLast ? closeButton : nextButton}
			/>
		</PersonSpeechOverlay>
	);
};

export default AreaTour;
