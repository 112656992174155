import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import type { Person } from '../../data/Persons';
import PERSONS from '../../data/Persons';

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-basis: 0;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
`;

const Avatar = styled.div`
	height: 16.8vmin;
	width: 16.8vmin;
`;

interface Props {
	person: Person;
}

const TourInfo: FunctionComponent<PropsWithChildren<Props>> = ({
	children,
	person
}) => {
	const personData = useMemo(() => {
		return PERSONS[person];
	}, [person]);

	const Image = useMemo(() => personData.image, [personData]);

	return (
		<Content>
			<Avatar>
				<Image />
			</Avatar>
			{children}
		</Content>
	);
};

export default TourInfo;
