import type { FunctionComponent, SVGProps } from 'react';

import SponsorsPng from './images/sponsors.png';
import MapPng from './images/map.png';
import { ReactComponent as SidebarSvgComponent } from './images/sidebar.svg';
import { ReactComponent as TitleSvgComponent } from './images/title.svg';
import { ReactComponent as PinSvgComponent } from './images/pin.svg';
// Icons
import { ReactComponent as BackSvgComponent } from './images/icons/back.svg';
import { ReactComponent as MobilitySvgComponent } from './images/icons/bicycle.svg';
import { ReactComponent as EnergySvgComponent } from './images/icons/leaf.svg';
import { ReactComponent as CommunitySvgComponent } from './images/icons/heart.svg';
import { ReactComponent as SimulationSvgComponent } from './images/icons/connect.svg';
import { ReactComponent as InfoSvgComponent } from './images/icons/info.svg';
// Persons
import { ReactComponent as BerndSvgComponent } from './images/persons/bernd.svg';
import { ReactComponent as LindaSvgComponent } from './images/persons/linda.svg';
import { ReactComponent as LisaSvgComponent } from './images/persons/lisa.svg';
import { ReactComponent as MartinSvgComponent } from './images/persons/martin.svg';
import { ReactComponent as SvenjaSvgComponent } from './images/persons/svenja.svg';
// Apps
// - Icons
import { ReactComponent as FishAndTippsIconSvgComponent } from './images/apps/icons/fishandtipps.svg';
import { ReactComponent as MiniLauternIconSvgComponent } from './images/apps/icons/minilautern.svg';
import { ReactComponent as PfaffFunkIconSvgComponent } from './images/apps/icons/pfafffunk.svg';
import { ReactComponent as SimulatorIconSvgComponent } from './images/apps/icons/simulator.svg';
// - Backgrounds
import { ReactComponent as FishAndTippsBackgroundSvgComponent } from './images/apps/backgrounds/fishandtipps.svg';
import { ReactComponent as MiniLauternBackgroundSvgComponent } from './images/apps/backgrounds/minilautern.svg';
import { ReactComponent as PfaffFunkBackgroundSvgComponent } from './images/apps/backgrounds/pfafffunk.svg';
import { ReactComponent as SimulatorBackgroundSvgComponent } from './images/apps/backgrounds/minilautern.svg';

export type SvgComponent = FunctionComponent<SVGProps<SVGSVGElement>>;

export interface AppImages {
	readonly icon: SvgComponent;
	readonly background: SvgComponent;
}

interface Images {
	readonly sponsors: string;
	readonly map: string;
	readonly sidebar: SvgComponent;
	readonly title: SvgComponent;
	readonly pin: SvgComponent;
	readonly back: SvgComponent;
	readonly mobility: SvgComponent;
	readonly energy: SvgComponent;
	readonly community: SvgComponent;
	readonly simulation: SvgComponent;
	readonly info: SvgComponent;
	readonly bernd: SvgComponent;
	readonly linda: SvgComponent;
	readonly lisa: SvgComponent;
	readonly martin: SvgComponent;
	readonly svenja: SvgComponent;
	readonly fishAndTipps: AppImages;
	readonly miniLautern: AppImages;
	readonly pfaffFunk: AppImages;
	readonly simulator: AppImages;
}

const IMAGES: Images = {
	sponsors: SponsorsPng,
	map: MapPng,
	sidebar: SidebarSvgComponent,
	title: TitleSvgComponent,
	pin: PinSvgComponent,
	back: BackSvgComponent,
	mobility: MobilitySvgComponent,
	energy: EnergySvgComponent,
	community: CommunitySvgComponent,
	simulation: SimulationSvgComponent,
	info: InfoSvgComponent,
	bernd: BerndSvgComponent,
	linda: LindaSvgComponent,
	lisa: LisaSvgComponent,
	martin: MartinSvgComponent,
	svenja: SvenjaSvgComponent,
	fishAndTipps: {
		icon: FishAndTippsIconSvgComponent,
		background: FishAndTippsBackgroundSvgComponent
	},
	miniLautern: {
		icon: MiniLauternIconSvgComponent,
		background: MiniLauternBackgroundSvgComponent
	},
	pfaffFunk: {
		icon: PfaffFunkIconSvgComponent,
		background: PfaffFunkBackgroundSvgComponent
	},
	simulator: {
		icon: SimulatorIconSvgComponent,
		background: SimulatorBackgroundSvgComponent
	}
};

const ASSETS = {
	images: IMAGES
};

export default ASSETS;
