import { IS_DEBUG } from '../utils/debug';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './resources/de.json';

export type TranslationKey = string;

i18n.use(initReactI18next).init({
	resources: {
		de
	},
	lng: 'de',
	fallbackLng: 'de',
	debug: IS_DEBUG,
	interpolation: {
		escapeValue: false // react already safes from xss
	},
	saveMissing: IS_DEBUG,
	missingKeyHandler: IS_DEBUG
		? (lngs: readonly string[], _: string, key: string) => {
				throw new Error(`Missing ${lngs} translation for '${key}'`);
		  }
		: false
});

export default i18n;
