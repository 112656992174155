import type { FunctionComponent, PropsWithChildren } from 'react';
import BaseRectangularButton from './BaseRectangularButton';
import type { Url } from '../../utils/types';

export interface Props {
	href: Url;
	target?: string;
}

const LinkButton: FunctionComponent<PropsWithChildren<Props>> = ({
	children,
	href,
	target = '_blank'
}) => {
	return (
		<BaseRectangularButton
			as="a"
			href={href}
			target={target}
			title={href}
			primary
		>
			{children}
		</BaseRectangularButton>
	);
};

export default LinkButton;
