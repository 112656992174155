import { useState } from 'react';
import { Area } from './Areas';
import type { TranslationKey } from '../i18n/i18n';
import type { Url } from '../utils/types';
import type { AppImages } from '../assets/Assets';
import ASSETS from '../assets/Assets';
import MiniLautern1 from '../assets/images/apps/screenshots/minilautern1.png';
import FishAndTipps1 from '../assets/images/apps/screenshots/fishandtipps1.png';
import FishAndTipps2 from '../assets/images/apps/screenshots/fishandtipps2.png';
import FishAndTipps3 from '../assets/images/apps/screenshots/fishandtipps3.png';
import FishAndTipps4 from '../assets/images/apps/screenshots/fishandtipps4.png';
import PfaffFunk1 from '../assets/images/apps/screenshots/pfaffunk1.png';
import PfaffFunk2 from '../assets/images/apps/screenshots/pfaffunk2.png';
import PfaffFunk3 from '../assets/images/apps/screenshots/pfaffunk3.png';
import Simulator1 from '../assets/images/apps/screenshots/simulator1.png';
import Simulator2 from '../assets/images/apps/screenshots/simulator2.png';

export enum App {
	FishAndTipps = 'fishAndTipps',
	MiniLautern = 'miniLautern',
	PfaffFunk = 'pfaffFunk',
	Simulator = 'simulator'
}

export interface Link {
	text: TranslationKey;
	url: Url;
}

interface Explanation {
	text: TranslationKey;
}

type Explanations = Explanation[];

type Screenshots = (Url | Url[])[];

export interface AppData {
	readonly name: TranslationKey;
	readonly title: TranslationKey;
	readonly description: TranslationKey;
	readonly area: Area;
	readonly images: AppImages;
	readonly links?: Link[];
	readonly screenshots: Screenshots;
	readonly explanations: Explanations;
}

const FISH_AND_TIPS: AppData = {
	name: 'app.fishAndTipps.name',
	title: 'app.fishAndTipps.title',
	description: 'app.fishAndTipps.description',
	area: Area.Energy,
	images: ASSETS.images.fishAndTipps,
	links: [
		{
			text: 'app.fishAndTipps.toYoutube',
			url: 'https://www.youtube.com/watch?v=0l8Sb2Zi-zk'
		}
	],
	screenshots: [[FishAndTipps1, FishAndTipps2, FishAndTipps3], FishAndTipps4],
	explanations: [
		{
			text: 'app.fishAndTipps.explanation1'
		},
		{
			text: 'app.fishAndTipps.explanation2'
		},
		{
			text: 'app.fishAndTipps.explanation3'
		},
		{
			text: 'app.fishAndTipps.explanation4'
		},
		{
			text: 'app.fishAndTipps.explanation5'
		}
	]
};

const MINI_LAUTERN: AppData = {
	name: 'app.miniLautern.name',
	title: 'app.miniLautern.title',
	description: 'app.miniLautern.description',
	area: Area.Mobility,
	images: ASSETS.images.miniLautern,
	links: [
		{
			text: 'app.miniLautern.toYoutubeTrailer',
			url: 'https://www.youtube.com/watch?v=yGS-yxWjA_I'
		},
		{
			text: 'app.miniLautern.toYoutubeBackground',
			url: 'https://www.youtube.com/watch?v=7Z7V9vlZBbA'
		},
		{
			text: 'app.miniLautern.toWebsite',
			url: 'https://www.minilautern.de/'
		}
	],
	screenshots: [MiniLautern1],
	explanations: [
		{
			text: 'app.miniLautern.explanation1'
		},
		{
			text: 'app.miniLautern.explanation2'
		},
		{
			text: 'app.miniLautern.explanation3'
		},
		{
			text: 'app.miniLautern.explanation4'
		}
	]
};

const PFAFF_FUNK: AppData = {
	name: 'app.pfaffFunk.name',
	title: 'app.pfaffFunk.title',
	description: 'app.pfaffFunk.description',
	area: Area.Community,
	images: ASSETS.images.pfaffFunk,
	links: [
		{
			text: 'app.pfaffFunk.toPlayStore',
			url: 'https://play.google.com/store/apps/details?id=de.fhg.iese.dd.dorffunk.pfaff.android'
		},
		{
			text: 'app.pfaffFunk.toAppStore',
			url: 'https://apps.apple.com/de/app/pfafffunk/id1584625795'
		}
	],
	screenshots: [[PfaffFunk1, PfaffFunk2, PfaffFunk3]],
	explanations: [
		{
			text: 'app.pfaffFunk.explanation1'
		},
		{
			text: 'app.pfaffFunk.explanation2'
		},
		{
			text: 'app.pfaffFunk.explanation3'
		},
		{
			text: 'app.pfaffFunk.explanation4'
		}
	]
};

const SIMULATOR: AppData = {
	name: 'app.simulator.name',
	title: 'app.simulator.title',
	description: 'app.simulator.description',
	area: Area.Simulation,
	images: ASSETS.images.simulator,
	links: [
		{
			text: 'app.simulator.toYoutube',
			url: 'https://www.youtube.com/watch?v=lIRhpPuDsEg'
		}
	],
	screenshots: [Simulator1, Simulator2],
	explanations: [
		{
			text: 'app.simulator.explanation1'
		},
		{
			text: 'app.simulator.explanation2'
		}
	]
};

type Apps = {
	[key in App]: AppData;
};

const APPS: Apps = {
	[App.FishAndTipps]: FISH_AND_TIPS,
	[App.MiniLautern]: MINI_LAUTERN,
	[App.PfaffFunk]: PFAFF_FUNK,
	[App.Simulator]: SIMULATOR
};

export const useExplanation = (explanations: Explanations) => {
	const [explanationIndex, setExplanationIndex] = useState(0);
	const explanationCount = explanations.length;
	const isFirst = explanationIndex - 1 < 0;
	const isLast = explanationIndex + 1 >= explanationCount;
	return {
		index: explanationIndex,
		explanation: explanations[explanationIndex],
		isFirst,
		onPrev: isFirst
			? undefined
			: () => setExplanationIndex((value) => value - 1),
		isLast,
		onNext: isLast ? undefined : () => setExplanationIndex((value) => value + 1)
	};
};

export default APPS;
