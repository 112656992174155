import type { FunctionComponent } from 'react';
import AppScreen from '../molecules/AppScreen.Container';
import { App } from '../../data/Apps';

interface Props {}

const MiniLauternScreen: FunctionComponent<Props> = () => {
	return <AppScreen app={App.MiniLautern}></AppScreen>;
};

export default MiniLauternScreen;
