import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import map from 'lodash/map';
import compact from 'lodash/compact';
import AppPin from './AppPin';
import type { App } from '../../data/Apps';

export interface AppPosition {
	readonly app: App;
	readonly x: number;
	readonly y: number;
}

interface Props {
	positions: AppPosition[];
	visible: boolean;
	onAppSelected: (app: App) => void;
}

const AppPinsOverlay: FunctionComponent<Props> = ({
	positions,
	visible,
	onAppSelected
}) => {
	const apps = useMemo(() => {
		return compact(
			map(positions, (position: AppPosition) => {
				return (
					<AppPin
						key={position.app}
						app={position.app}
						visible={visible}
						x={position.x}
						y={position.y}
						onClick={() => onAppSelected(position.app)}
					/>
				);
			})
		);
	}, [positions, onAppSelected, visible]);

	return <>{apps}</>;
};

export default AppPinsOverlay;
