import React, { useCallback, useMemo, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Options from '../molecules/Options';
import type { Item } from '../molecules/Options';
import Rating from '../molecules/Rating';
import Textarea from '../molecules/Textarea';
import RectangularButton from '../atoms/RectangularButton';
import type { App } from '../../data/Apps';

const Question = styled.fieldset`
	border: 0 none;
	margin: 0 0 1em;
	padding: 0;
`;
const Description = styled.p`
	margin: 0.4em 0;
`;
const Actions = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

//#region Translations

const useYesNoItems = (): Item[] => {
	const { t } = useTranslation();
	return useMemo(
		() => [
			{ title: t('survey.yesno.yes'), value: 'ja' },
			{ title: t('survey.yesno.no'), value: 'nein' }
		],
		[t]
	);
};

//#endregion Translations

//#region Actions

interface BackNextProps {
	onBack?: () => void;
	onNext?: () => void;
}

const BackNext: FunctionComponent<BackNextProps> = ({ onBack, onNext }) => {
	const { t } = useTranslation();

	return (
		<Actions>
			{onBack && (
				<RectangularButton onClick={onBack}>
					{t('survey.back')}
				</RectangularButton>
			)}
			{onNext && (
				<RectangularButton primary onClick={onNext}>
					{t('survey.next')}
				</RectangularButton>
			)}
		</Actions>
	);
};

interface FurtherQuestionProps {
	onYes: () => void;
	onNo: () => void;
}

const FurtherQuestions: FunctionComponent<FurtherQuestionProps> = ({
	onNo,
	onYes
}) => {
	const { t } = useTranslation();

	return (
		<Question>
			<Description>
				Hast du Lust, noch einige kurze Fragen zu den Guides zu beantworten?
			</Description>
			<Actions>
				<RectangularButton onClick={onNo}>{t('survey.no')}</RectangularButton>
				<RectangularButton primary onClick={onYes}>
					{t('survey.yes')}
				</RectangularButton>
			</Actions>
		</Question>
	);
};

interface BackFinishProps {
	sending: boolean;
	onBack?: () => void;
	onFinish: () => void;
}

const BackFinish: FunctionComponent<BackFinishProps> = ({
	sending,
	onBack,
	onFinish
}) => {
	const { t } = useTranslation();

	return (
		<Actions>
			{onBack && (
				<RectangularButton onClick={onBack}>
					{t('survey.back')}
				</RectangularButton>
			)}
			<RectangularButton primary disabled={sending} onClick={onFinish}>
				{t('survey.finish')}
			</RectangularButton>
		</Actions>
	);
};

//#endregion Actions

interface PageRatingProps {
	rating?: number;
	onRatingChanged: (value: number) => void;
}
const PageRating: FunctionComponent<PageRatingProps> = ({
	rating,
	onRatingChanged
}) => {
	return (
		<>
			<Question>
				<Description>Sternebewertung der Landkarte von 1 – 5</Description>
				<Rating name="rating" value={rating} onChanged={onRatingChanged} />
			</Question>
		</>
	);
};

interface PageVisualizationProps {
	rating?: string;
	notes?: string;
	onRatingChanged: (value: string) => void;
	onNotesChanged: (value: string) => void;
}

const PageVisualization: FunctionComponent<PageVisualizationProps> = ({
	rating,
	notes,
	onRatingChanged,
	onNotesChanged
}) => {
	const yesNoItems = useYesNoItems();

	return (
		<>
			<Question>
				<Description>
					Gefällt dir die visuelle Umsetzung der Landkarte?
				</Description>
				<Options
					name="rating"
					items={yesNoItems}
					selectedValue={rating}
					onChange={onRatingChanged}
				/>
			</Question>
			<Question>
				<Description>Falls nicht, was gefällt dir nicht</Description>
				<Textarea
					name="notes"
					placeholder="Tippe..."
					value={notes}
					onChange={onNotesChanged}
				/>
			</Question>
		</>
	);
};

interface PageTextUnderstandingProps {
	rating?: string;
	notes?: string;
	onRatingChanged: (value: string) => void;
	onNotesChanged: (value: string) => void;
}

const PageTextUnderstanding: FunctionComponent<PageTextUnderstandingProps> = ({
	rating,
	notes,
	onRatingChanged,
	onNotesChanged
}) => {
	const yesNoItems = useYesNoItems();

	return (
		<>
			<Question>
				<Description>Sind die Texte für dich verständlich?</Description>
				<Options
					name="rating"
					items={yesNoItems}
					selectedValue={rating}
					onChange={onRatingChanged}
				/>
			</Question>
			<Question>
				<Description>Falls nicht, was ist nicht verständlich</Description>
				<Textarea
					name="notes"
					placeholder="Tippe..."
					value={notes}
					onChange={onNotesChanged}
				/>
			</Question>
		</>
	);
};

interface PageImprovementProps {
	notes?: string;
	onNotesChanged: (value: string) => void;
}

const PageImprovement: FunctionComponent<PageImprovementProps> = ({
	notes,
	onNotesChanged
}) => {
	return (
		<>
			<Question>
				<Description>
					Hast du Vorschläge, wie wir die Landkarte verbessern können?
				</Description>
				<Textarea
					name="notes"
					placeholder="Tippe..."
					value={notes}
					onChange={onNotesChanged}
				/>
			</Question>
		</>
	);
};

interface PageAreasProps {
	notes?: string;
	onNotesChanged: (value: string) => void;
}

const PageAreas: FunctionComponent<PageAreasProps> = ({
	notes,
	onNotesChanged
}) => {
	return (
		<>
			<Question>
				<Description>
					Hast du Anmerkungen, zu den verschiedenen Bereichen{' '}
					<strong>Mobilität</strong>, <strong>Energie</strong>,{' '}
					<strong>Community</strong> oder dem{' '}
					<strong>Quartierssimulator</strong>?
				</Description>
				<Textarea
					name="notes"
					placeholder="Tippe..."
					value={notes}
					onChange={onNotesChanged}
				/>
			</Question>
		</>
	);
};

interface SurveyData {
	rating?: number;
	visualization?: string;
	visualizationNotes?: string;
	textLength?: string;
	textUnderstanding?: string;
	textUnderstandingNotes?: string;
	improvementNotes?: string;
	areasNotes?: string;
}

interface PageInfo {
	app?: App;
	component: React.ReactNode;
}

interface Props {
	apps: App[];
	sending: boolean;
	onFinish: (data: SurveyData) => void;
}

const Survey: FunctionComponent<Props> = ({ apps, sending, onFinish }) => {
	const [data, setData] = useState<SurveyData>({});
	const [pageIndex, setPageIndex] = useState(0);

	const back = useCallback(() => {
		setPageIndex((index) => index - 1);
	}, [setPageIndex]);

	const next = useCallback(() => {
		setPageIndex((index) => index + 1);
	}, [setPageIndex]);

	const finish = useCallback(() => {
		onFinish(data);
	}, [onFinish, data]);

	const pages = useMemo((): PageInfo[] => {
		const pageInfos: PageInfo[] = [];
		pageInfos.push(
			{
				component: (
					<PageRating
						rating={data.rating}
						onRatingChanged={(rating) =>
							setData((value) => ({ ...value, rating }))
						}
					/>
				)
			},
			{
				component: (
					<PageVisualization
						rating={data.visualization}
						onRatingChanged={(visualization) =>
							setData((value) => ({ ...value, visualization }))
						}
						notes={data.visualizationNotes}
						onNotesChanged={(visualizationNotes) =>
							setData((value) => ({
								...value,
								visualizationNotes
							}))
						}
					/>
				)
			},
			{
				component: (
					<PageTextUnderstanding
						rating={data.textUnderstanding}
						onRatingChanged={(textUnderstanding) =>
							setData((value) => ({ ...value, textUnderstanding }))
						}
						notes={data.textUnderstandingNotes}
						onNotesChanged={(textUnderstandingNotes) =>
							setData((value) => ({
								...value,
								textUnderstandingNotes
							}))
						}
					/>
				)
			},
			{
				component: (
					<PageImprovement
						notes={data.improvementNotes}
						onNotesChanged={(improvementNotes) =>
							setData((value) => ({
								...value,
								improvementNotes
							}))
						}
					/>
				)
			},
			{
				component: (
					<PageAreas
						notes={data.areasNotes}
						onNotesChanged={(areasNotes) =>
							setData((value) => ({ ...value, areasNotes }))
						}
					/>
				)
			}
		);
		return pageInfos;
	}, [data, setData]);

	const currentPage = useMemo(() => {
		if (pageIndex >= 0 && pageIndex < pages.length) {
			return pages[pageIndex].component;
		}
		return null;
	}, [pages, pageIndex]);

	const isFirst = pageIndex === 0;
	const isLast = pageIndex === pages.length - 1;
	const isLastInGeneral =
		!isLast && pages[pageIndex].app == null && pages[pageIndex + 1].app != null;

	const actions = useMemo(() => {
		if (isLast) {
			return (
				<BackFinish
					sending={sending}
					onBack={isFirst ? undefined : back}
					onFinish={finish}
				/>
			);
		}
		if (isLastInGeneral) {
			return <FurtherQuestions onNo={finish} onYes={next} />;
		}
		return (
			<BackNext
				onBack={isFirst ? undefined : back}
				onNext={isLast ? undefined : next}
			/>
		);
	}, [back, next, finish, sending, isFirst, isLast, isLastInGeneral]);

	return (
		<>
			{currentPage}
			{actions}
		</>
	);
};

export default Survey;
