import { useEffect, useMemo } from 'react';
import type { CSSProperties, FunctionComponent } from 'react';
import styled from 'styled-components';
import AppDescription from './AppDescription';
import AppTextSpeechBubble from './AppTextSpeechBubble';
import Images from './Images';
import Links from './Links';
import type { App } from '../../data/Apps';
import APPS, { useExplanation } from '../../data/Apps';
import { getPersonForArea } from '../../data/Persons';

const Screen = styled.div`
	background-color: ${(props) => props.theme.colors.lightGray};
	bottom: 0;
	left: 0;
	padding-left: 120px;
	padding-right: 2em;
	padding-top: 2em;
	position: absolute;
	right: 0;
	top: 0;
`;

const Content = styled.div`
	align-items: stretch;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 100%;
	max-height: 100%;
	position: relative;
`;

const Header = styled.div`
	margin-left: 6em;
`;

const Top = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	& > *:first-child {
		max-width: 50%;
	}
`;

const Center = styled.div`
	align-items: stretch;
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-wrap: nowrap;
	gap: 1em;
	justify-content: space-between;
	min-height: 0;
	overflow: hidden;
`;

const Info = styled.div`
	flex: 1;
	margin-top: 1em;
	padding-bottom: 16vmin;
	padding-left: 22vmin;
`;

const Avatar = styled.div`
	bottom: 0;
	font-size: 0; // Prevents space underneath
	left: -14vmin;
	pointer-events: none;
	position: absolute;
	width: 50vmin;
`;

const AppImages = styled(Images)`
	flex: 2;
	margin-bottom: 2em;
	margin-top: 1em;
`;

const BACKGROUND_STYLE: CSSProperties = {
	position: 'absolute',
	left: 0,
	right: 0,
	bottom: 0
};

export interface StateProps {}

export interface DispatchProps {
	onViewed: (app: App) => void;
}

export interface OwnProps {
	app: App;
}

type Props = StateProps & DispatchProps & OwnProps;

const AppScreen: FunctionComponent<Props> = ({ app, onViewed }) => {
	const appData = useMemo(() => APPS[app], [app]);
	const { explanation, onPrev, onNext } = useExplanation(appData.explanations);

	const explanationComponent = useMemo(() => {
		return (
			<AppTextSpeechBubble
				textKey={explanation.text}
				onPrev={onPrev}
				onNext={onNext}
			/>
		);
	}, [explanation, onPrev, onNext]);

	const Background = useMemo(() => appData.images.background, [appData]);

	useEffect(() => {
		onViewed(app);
	}, [onViewed, app]);

	const PersonImage = useMemo(
		() => getPersonForArea(appData.area)?.image,
		[appData]
	);

	return (
		<Screen>
			<Background style={BACKGROUND_STYLE} />
			<Content>
				<Header>
					<Top>
						<AppDescription data={appData} />
					</Top>
					<Links links={appData.links} />
				</Header>
				<Center>
					<Info>
						{PersonImage && (
							<Avatar>
								<PersonImage />
							</Avatar>
						)}
						{explanationComponent}
					</Info>
					<AppImages urls={appData.screenshots} />
				</Center>
			</Content>
		</Screen>
	);
};

export default AppScreen;
